/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";
import addToast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";

import DropdownMap from "./Dropdown/DropdownMap";
import DropdownLocation from "./Dropdown/DropdownLocation";
import DropdownPeriod from "./Dropdown/DropdownPeriod";
import Map from "./Map";
import ModalMenu from "./Modal/ModalMenu";

import "./App.scss";
import iconMapMarker from "./svg/icon__map-marker.svg";
import iconTable from "./svg/icon__table.svg";
import Menu from "./Dropdown/DropdownMenu";
import ModalDownload from "./Modal/ModalDownload";
import ScaleLoader from "react-spinners/ScaleLoader";

function App() {
  const [token, setToken] = useState(false);
  const [data, setData] = useState([]);
  const [dataDownloads, setDataDownloads] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [view, setView] = useState(false); // false — table view, true — map view
  const [modal, setModal] = useState(false); // false — table view, true — map view

  const [menuActive, setMenuActive] = useState(false);
  const [menuLang, setMenuLang] = useState("en");
  const [mapActive, setMapActive] = useState(false);
  const [locationActive, setLocationActive] = useState(false);
  const [periodActive, setPeriodActive] = useState(false);
  const [tipsActive, setTipsActive] = useState(false);

  const [mapCurrent, setMapCurrent] = useState([]);
  const [locationCurrent, setLocationCurrent] = useState([]);
  const [periodCurrent, setPeriodCurrent] = useState("");
  const [phoneticCurrent, setPhoneticCurrent] = useState("");
  const [menuCurrent, setMenuCurrent] = useState("");

  const [locations, setLocations] = useState([]);
  const [mapName, setMapName] = useState({});
  const [mapLanguage, setMapLanguage] = useState("name_it");
  const [pastTranscription, setPastTranscription] = useState(1); // 0 — AIS, 1 — IPA
  // const [selectedItems, setSelectedItems] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastChecked, setLastChecked] = useState(null);
  const [loading, setLoading] = useState(false);

  const [firstLoad, setFirstLoad] = useState(0);
  toastConfig({ theme: "dark", position: "bottom-left" });

  const menuLabels = [
    ["The Project", "Il progetto"],
    ["How To Use", "Istruzioni per l'uso"],
    ["Questionnaire", "Questionario"],
    ["Metadata", "Metadati"],
    ["Research Team", "Gruppo di ricerca"],
    ["Credits", "Come citare"],
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterByPhonetic(phoneticCurrent);
  }, [data]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  async function locationsSearch(value = "", page = 1, tempToken = token) {
    await axios
      .get(`https://romais01.uzh.ch/service/api/v1/ais/locations/?page=${page}&query=${value}`, {
        headers: { authorization: "Token " + tempToken },
      })
      .then(function (response) {
        setLocations(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function dataSearch(maps = mapCurrent, location = "", period = "", tempToken = token) {
    if (!maps.length) {
      setData([]);
      return;
    }
    setSelectedRows((prevRows) =>
      prevRows.filter((item) => maps.includes(item.map) && location.includes(item.location) && (period === "" ? true : item.period === period))
    );
    await axios
      .get(
        `https://romais01.uzh.ch/service/api/v1/ais/lexical_variants/?page=${1}${
          location.length ? "&location__in=" + location.join(",") : ""
        }&period=${period}&map__in=${maps.join(",")}`,
        {
          headers: { authorization: "Token " + tempToken },
        }
      )
      .then((response) => {
        let returnData = response.data.results;
        if (location.length > 0) {
          for (let i = 0; i < location.length; i++) {
            let index = response.data.results.findIndex((item) => {
              return item.location === location[i];
            });
            if (index < 0) {
              for (let k = 0; k < maps.length; k++) {
                let nullObject = {
                  id: null,
                  ais: "—",
                  ipa: "—",
                  audio: null,
                  period: period,
                  location: location[i],
                  map: null,
                };
                nullObject["map"] = maps[k];
                if (!period) {
                  nullObject["period"] = "past";
                  returnData.push(nullObject);
                  let newNullObject = { ...nullObject, period: "present" };
                  returnData.push(newNullObject);
                } else {
                  returnData.push(nullObject);
                }
              }
            }
          }
        }
        returnData.sort((a, b) => a.map - b.map);
        let updatedReturnData = returnData.map((item) => {
          let newItem = { ...item };
          newItem.checkId = item.period + "_" + item.location + "_" + item.map;
          return newItem;
        });
        return updatedReturnData;
      })
      .then(async (data) => {
        setData(data);
        const mapData = await findMaps(maps, tempToken);
        return mapData;
      })
      .then((mapData) => {
        var newMaps = {};
        mapData.forEach((value) => {
          newMaps[value["id"]] = {};
          newMaps[value["id"]].name_it = value.map_id + " – " + value.name_it;
          newMaps[value["id"]].name_fr = value.map_id + " – " + value.name_fr;
          newMaps[value["id"]].name_de = value.map_id + " – " + value.name_de;
        });
        setMapName(newMaps);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchData() {
    let tempToken = "";
    await axios
      .post("https://romais01.uzh.ch/service/api/v1/api-token-auth/", {
        username: "admin",
        password: "admin",
      })
      .then(function (response) {
        setToken(response.data.token);
        tempToken = response.data.token;
      })
      .catch(function (error) {
        console.log(error);
      });

    await locationsSearch("", 1, tempToken);
    await dataSearch(mapCurrent, "", "", tempToken);
  }

  function handleClickOutside(event) {
    if (!document.getElementsByClassName("menu-wrapper")[0].contains(event.target)) {
      setMenuActive(false);
    }
    const dropdowns = document.getElementsByClassName("dropdown-wrapper");
    if (!dropdowns[0].contains(event.target)) {
      setMapActive(false);
    }
    if (!dropdowns[1].contains(event.target)) {
      setLocationActive(false);
    }
    if (!dropdowns[2].contains(event.target)) {
      setPeriodActive(false);
    }
  }

  async function findMaps(id, tempToken) {
    return await axios
      .get(`https://romais01.uzh.ch/service/api/v1/ais/maps/?id__in=${id.join(",")}`, {
        headers: { authorization: "Token " + tempToken },
      })
      .then(function (response) {
        return response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function findLocation(id) {
    let temp = locations.find((obj) => obj.id === id);
    return temp ? temp.ais_id + " – " + temp.name : "<span style='color:#e0e0e0'>—</span>";
  }

  function filterByPhonetic(value) {
    setPhoneticCurrent(value);
    value = value.replace(/[\[\]\\\/^$.|?*+(){}]/g, "\\$&");
    value = value.replace(/#/g, ".");
    value = value.replace(/_/g, ".+");
    const regex = new RegExp("^" + value + "$", "g");
    setFilteredData(data.filter(({ ipa }) => ipa.match(regex)));
  }

  function playAudio(file) {
    const audio = new Audio(file);
    audio.volume = 0.5;
    audio.play();
  }

  // var audioContext = new AudioContext();

  // async function loadAudio(url) {
  //   let response = await fetch(url);
  //   console.log("Response => ", response);
  //   let arrayBuffer = await response.arrayBuffer();
  //   return arrayBuffer.decodeAudioData(arrayBuffer);
  // }

  // // Function to play an audio buffer with a gain node
  // function playAudio(audioBuffer) {

  //   var source = audioContext.createBufferSource();

  //   source.buffer = audioBuffer;

  //   var gainNode = audioContext.createGain();

  //   source.connect(gainNode);
  //   gainNode.connect(audioContext.destination);
  //   source.start();
  // }

  async function download(id) {
    await axios
      .get(`https://romais01.uzh.ch/service/ais/data-downloader/${id}`, {
        headers: { authorization: "Token " + token },
      })
      .then(function (response) {
        if (response.data.status === "finished") {
          let a = document.createElement("a");
          a.href = response.data.file;
          a.setAttribute("download", "ais");
          a.click();
          setDataDownloads(false);
          return;
        } else if (response.data.status === "processing") {
          setTimeout(download, 200, id);
          return;
        }
        addToast("Success", { duration: 3000 });
        setDataDownloads(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async function makeDownloader(data) {
    let requestData = data;
    if (dataDownloads === true) return;
    setLoading(true);
    let fields = selectedRows.map((el) => {
      return el.id;
    });
    requestData.lexical_variants = "[" + fields.join(",") + "]";

    await axios
      .post("https://www.ais-reloaded.uzh.ch/service/api/v1/ais/downloaders/", requestData, {
        headers: { authorization: "Token " + token },
      })
      .then((response) => {
        download(response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleCheckboxChange = (index, isChecked, rowData, event) => {
    if (event.shiftKey && lastChecked !== null && index !== lastChecked) {
      const start = Math.min(lastChecked, index);
      const end = Math.max(lastChecked, index);
      // setSelectedItems((prevItems) => {
      //   let newSelectedItems = { ...prevItems };
      //   for (let i = start; i <= end; i++) {
      //     newSelectedItems[data[i]?.checkId] = isChecked;
      //   }
      //   return newSelectedItems;
      // });

      setSelectedRows((prevRows) => {
        let newSelectedRows = [...prevRows];
        if (isChecked) {
          for (let i = start; i <= end; i++) {
            if (newSelectedRows.findIndex((item) => item.checkId === data[i].checkId) < 0) {
              newSelectedRows.push(data[i]);
            }
          }
        } else {
          newSelectedRows = newSelectedRows.filter((item, idx) => idx < start || idx > end);
        }
        return newSelectedRows;
      });
    } else {
      // setSelectedItems((prevItems) => ({
      //   ...prevItems,
      //   [rowData?.checkId]: isChecked,
      // }));
      setSelectedRows((prevRows) => (isChecked ? [...prevRows, rowData] : prevRows.filter((item) => item.checkId !== rowData.checkId)));
    }
    setLastChecked(index);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const listItems = (phoneticCurrent ? filteredData : data).map((row, index) => {
    return (
      <tr
        key={index}
        onClick={(e) => {
          if (e.target.type !== "checkbox") {
            handleCheckboxChange(index, selectedRows.findIndex((item) => item?.checkId === row?.checkId) === -1, row, e);
          }
        }}
        style={{ backgroundColor: selectedRows.findIndex((item) => item?.checkId === row?.checkId) !== -1 ? "#e0e0e0" : "" }}
      >
        <td style={{ width: "5%" }}>
          <input
            type="checkbox"
            checked={selectedRows.findIndex((item) => item?.checkId === row?.checkId) !== -1 || false}
            onChange={(e) => {
              e.stopPropagation();
              handleCheckboxChange(index, e.target.checked, row, e);
            }}
          />
        </td>
        <td
          style={{ width: "20%" }}
          dangerouslySetInnerHTML={{
            __html: row.ais ? row.ais : "<span style='color:#ADADAD'>—</span>",
          }}
        />
        <td style={{ width: "20%" }} dangerouslySetInnerHTML={{ __html: row.ipa }} />
        <td style={{ width: "10%" }}>{row.period}</td>
        <td style={{ width: "20%" }}>{findLocation(row.location)}</td>
        <td className="map-column">
          <div>{mapName[row.map] !== undefined ? mapName[row.map][mapLanguage] : "—"}</div>
          {row.audio && row.period === "present" ? (
            <div
              className="play"
              onClick={(e) => {
                e.stopPropagation();
                // loadAudio(row.audio).then(playAudio);
                playAudio(row.audio);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.1829 11.6527C18.4516 11.8063 18.4516 12.1937 18.1829 12.3473L7.19845 18.6241C6.93179 18.7765 6.6 18.584 6.6 18.2768L6.6 5.72318C6.6 5.41605 6.93179 5.2235 7.19846 5.37588L18.1829 11.6527Z"
                  strokeWidth="1.2"
                />
              </svg>
              Play
            </div>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="App">
      <ModalMenu
        isActive={modal}
        current={menuCurrent}
        labels={menuLabels}
        onClose={() => setModal(false)}
        onLangChange={(lang) => setMenuLang(lang)}
        onMenuChange={(name) => setMenuCurrent(name)}
      />
      <ModalDownload download={makeDownloader} isOpen={isModalOpen} onClose={handleCloseModal} />
      <header>
        <div className="logo">AIS,r</div>
        <div className="filters">
          <button className="btn-switch" onClick={() => setView(!view)} disabled={mapCurrent.length > 1}>
            <div className="content" tabIndex="-1">
              <div className="label">{view ? "Switch to table view" : "Switch to map view"}</div>
              <TransitionGroup component={null}>
                <CSSTransition key={view} timeout={160} classNames="switch">
                  {view ? <img src={iconTable} alt="Table icon" /> : <img src={iconMapMarker} alt="Map icon" />}
                </CSSTransition>
              </TransitionGroup>
            </div>
          </button>

          {token && (
            <DropdownMap
              label={
                mapCurrent.length === 1
                  ? mapName[mapCurrent[0]] && mapName[mapCurrent[0]][mapLanguage]
                  : mapCurrent.length
                  ? mapCurrent.length + " maps selected"
                  : null
              }
              firstLoad={firstLoad}
              isActive={mapActive}
              token={token}
              onToggle={() => {
                setMapActive(!mapActive);
                setLocationActive(false);
                setPeriodActive(false);
                setMenuActive(false);
              }}
              onValueChange={(el, t = token) => {
                if (el.length > 1) setView(false);
                setMapCurrent(el);
                dataSearch(el, locationCurrent, periodCurrent, t);
                setFirstLoad(firstLoad + 1);
              }}
              onLangChange={(lang) => {
                setMapLanguage(lang);
              }}
            />
          )}

          <DropdownLocation
            label={
              locationCurrent.length === 1 ? findLocation(locationCurrent[0]) : locationCurrent.length ? locationCurrent.length + " locations selected" : null
            }
            isActive={locationActive}
            list={locations}
            onToggle={() => {
              setLocationActive(!locationActive);
              setMapActive(false);
              setPeriodActive(false);
              setMenuActive(false);
            }}
            onValueChange={(el) => {
              dataSearch(mapCurrent, el, periodCurrent);
              setLocationCurrent(el);
            }}
          />

          <DropdownPeriod
            label="Period"
            isActive={periodActive}
            pastTranscription={pastTranscription}
            onTranscriptionChange={(value) => {
              setPastTranscription(value);
            }}
            onToggle={() => {
              setPeriodActive(!periodActive);
              setMapActive(false);
              setLocationActive(false);
              setMenuActive(false);
            }}
            onValueChange={(el) => {
              setPeriodActive(false);
              setPeriodCurrent(el);
              dataSearch(mapCurrent, locationCurrent, el);
            }}
          />

          <div className="input-text">
            <input
              type="text"
              placeholder="Phonetic"
              defaultValue={phoneticCurrent}
              onChange={(e) => {
                filterByPhonetic(e.target.value);
              }}
              onFocus={() => setTipsActive(true)}
              onBlur={() => setTipsActive(false)}
            />
            <CSSTransition in={tipsActive} timeout={120} classNames="dropdown" unmountOnExit>
              <div className="dropdown-drop phonetic">
                <table>
                  <tbody>
                    <tr className="phonetic-tip">
                      <td>
                        <div className="operator">#</div>
                      </td>
                      <td className="label">one symbol</td>
                    </tr>
                    <tr className="phonetic-tip">
                      <td>
                        <div className="operator">_</div>
                      </td>
                      <td className="label">multiple symbols</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CSSTransition>
          </div>
        </div>

        <Menu
          isActive={menuActive}
          labels={menuLabels}
          lang={menuLang}
          onCheck={(name) => {
            setMenuCurrent(name);
            setModal(true);
          }}
          onToggle={() => setMenuActive(!menuActive)}
        />
      </header>
      {view ? (
        <>
          <div className="legend">
            <div className="item">
              <div className="cluster"></div>
              Set of points in the area (not datapoint ID!)
            </div>
            <div className="item">
              <div className="id_char">#</div>
              Datapoint ID
            </div>
            <div className="item">
              <div className="transcript">trˈänskrįpt</div>= early 20th century data
            </div>
            <div className="item">
              <div className="transcript cyan">ˈtrænskrɪpt</div>= early 21th century data
            </div>
          </div>
          <Map data={phoneticCurrent ? filteredData : data} periodCurrent={periodCurrent} pastTranscription={pastTranscription} locations={locations} />
        </>
      ) : (
        <main>
          {loading ? (
            <div className="spinner-container">
              <ScaleLoader color="#50c2c9" loading={loading} size={100} aria-label="Loading Spinner" data-testid="loader" />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>#</th>
                  <th style={{ width: "20%" }}>AIS</th>
                  <th style={{ width: "20%" }}>IPA</th>
                  <th style={{ width: "10%" }}>Period</th>
                  <th style={{ width: "20%" }}>Location</th>
                  <th className="map-column">
                    <div>Map</div>
                    {data.length !== 0 && (
                      <div
                        className={(selectedRows.length === 0 || selectedRows.length > 100 ? "disabled" : "") + " download"}
                        onClick={() => {
                          if (selectedRows.length > 0 && selectedRows.length <= 100) {
                            handleOpenModal();
                            // setDataDownloads(true);
                            // makeDownloader();
                          }
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.5 5V16M11.5 16L15.5 12M11.5 16L7.5 12" strokeWidth="1.25" />
                          <path d="M4.5 17V19.5H18.5V17" strokeWidth="1.25" />
                        </svg>
                        Download table
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>{listItems}</tbody>
            </table>
          )}
        </main>
      )}
    </div>
  );
}

export default App;
