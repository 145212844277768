import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./Modal.scss";
import iconClose from "../svg/icon__close.svg";

const ModalDownload = ({ isOpen, onClose, download }) => {
  const [lang, setLang] = useState("en");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    download(formData, lang);
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      reason: "",
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <CSSTransition in={isOpen} timeout={160} classNames="modal" unmountOnExit>
      <div
        className="modal"
        onClick={(e) => {
          if (e.target === document.getElementsByClassName("modal")[0])
            onClose();
        }}
      >
        <div className="window">
          <div className="head" style={{display:'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '10px 20px'}}>
            <div className="control">
              <div className="languages">
                <div
                  className={"lang " + (lang === "en" ? "active" : "alt")}
                  onClick={() => {
                    setLang("en");
                  }}
                >
                  EN
                </div>
                <div
                  className={"lang " + (lang === "it" ? "active" : "alt")}
                  onClick={() => {
                    setLang("it");
                  }}
                >
                  IT
                </div>
              </div>
              <img src={iconClose} alt="Close icon" onClick={() => onClose()} />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
          {lang === "en" ? (
            <div className="window-content en">
              <div>
                <div>
                  <label>
                    FirstName:
                    <input
                      className="downloadmodal-input"
                      type="text"
                      id="firstName"
                      name="first_name"
                      placeholder="FirstName"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>
                <div>
                  <label>
                    LastName:
                    <input
                      className="downloadmodal-input"
                      type="text"
                      id="lastName"
                      name="last_name"
                      placeholder="LastName"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Email:
                    <input
                      className="downloadmodal-input"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Download Reason
                    <textarea
                      id="reason"
                      name="reason"
                      placeholder="Reason"
                      value={formData.reason}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>

                <div>
                  <div className="header">Data licensing</div>
                  <div className="description">
                    This work is licensed under a Creative Commons Attribution
                    4.0 International License ({" "}
                    <a href="https://creativecommons.org/licenses/by/4.0/">
                      CC BY 4.0 Deed | Attribution 4.0 International |
                      Creative Commons
                    </a>{" "}
                    ) in compliance with the Swiss National Science Foundation
                    requirements. Users must give appropriate credit, provide
                    a link to the website, and indicate if changes were made.
                    They may do so in any reasonable manner, but not in any
                    way that suggests the licensor endorses the users or their
                    use. This applies to the whole content of the web database
                    (i.e. audios and transcriptions of newly acquired data,
                    but also the digitalized data of the original AIS).
                  </div>
                  <div className="header">Credits</div>
                  <div className="description">
                    Michele Loporcaro, Stephan Schmid, Diego Pescarini,
                    Graziano Tisato, Giulia Donzelli, Lisa Gasner, Alice
                    Idone, Stefano Negrinelli, Adriano Salvi, Chiara Zanini
                    (2022). <i>AIS, reloaded</i>
                    (AISr). University of Zurich. {" "}
                    <a href="https://www.ais-reloaded.uzh.ch/">
                      https://www.ais-reloaded.uzh.ch/
                    </a>
                  </div>
                  <div className="header">Data collection and processing</div>
                  <div className="description">
                    By submitting the form, you give consent to the processing
                    of your personal data for the purpose of monitoring the
                    traffic of the AISr and AISdt web database. Your data will
                    be stored in our servers. They will be accessible to the
                    administrators of the website only and will not be shared
                    with third-party applications. Processing of the personal
                    data gathered is legally based on Art. 6 para. 1 lit. b EU
                    General Data Protection Regulation (GDPR) {" "}
                    <a href="https://gdpr-info.eu/art-6-gdpr/">
                      Art. 6 GDPR – Lawfulness of processing - General Data
                      Protection Regulation (GDPR)
                    </a>
                  </div>
                </div>

                <div className="download-button">
                  <button type="submit">Submit to download</button>
                </div>
              </div>
            </div>
          ) : (
            <div className="window-content it">
              <div>
                <div>
                  <label>
                    Nome:
                    <input
                      className="downloadmodal-input"
                      type="text"
                      id="firstName"
                      name="first_name"
                      placeholder="Nome"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Cognome:
                    <input
                      className="downloadmodal-input"
                      type="text"
                      id="lastName"
                      name="last_name"
                      placeholder="Cognome"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Email:
                    <input
                      className="downloadmodal-input"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Finalità del download
                    <textarea
                      id="reason"
                      name="reason"
                      placeholder="Motivo"
                      value={formData.reason}
                      onChange={handleChange}
                      required
                    />
                  </label>
                </div>

                <div>
                  <div className="header">
                    Condizioni per l'utilizzo dei dati
                  </div>
                  <div className="description">
                    Quest'opera è distribuita con Licenza Internazionale
                    Creative Commons Attribuzione 4.0 ({" "}
                    <a href="https://creativecommons.org/licenses/by/4.0/">
                      CC BY 4.0 Deed | Attribuzione 4.0 Internazionale |
                      Creative Commons
                    </a>{" "}
                    ), in ottemperanza alle direttive del Fondo Nazionale
                    Svizzero per la Ricerca Scientifica. L'utente deve
                    menzionare adeguatamente gli autori dell'opera, fornire un
                    link al sito e indicare se sono state effettuate delle
                    modifiche ai contenuti originali. Ciò può essere fatto in
                    modalità tali da non suggerire che il licenziante avalli
                    le modifiche o l'utilizzo del materiale da parte
                    dell'utente. Tali regole si applicano all'intero contenuto
                    della banca dati (ad esempio, non solo alle registrazioni
                    e alle trascrizioni dei dati di nuova acquisizione, ma
                    anche ai dati digitalizzati dell'AIS originale).
                  </div>
                  <div className="header">Come citare</div>
                  <div className="description">
                    Michele Loporcaro, Stephan Schmid, Diego Pescarini,
                    Graziano Tisato, Giulia Donzelli, Stefano Negrinelli,
                    Chiara Zanini (2019). AIS, reloaded (AISr). Università di
                    Zurigo. {" "}
                    <a href="https://www.ais-reloaded.uzh.ch/">
                      AIS, reloaded
                    </a>{" "}
                    Michele Loporcaro, Giulia Donzelli, Lisa Gasner, Alice
                    Idone, Adriano Salvi, Graziano Tisato (2021). AIS, the
                    digital turn (AISdt). Università di Zurigo. {" "}
                    <a href="https://www.ais-reloaded.uzh.ch/">
                      AIS, reloaded
                    </a>
                  </div>
                  <div className="header">
                    Informativa per la raccolta e trattamento dei dati
                    personali
                  </div>
                  <div className="description">
                    Procedendo con l'operazione, acconsente al trattamento dei
                    propri dati personali al fine di monitorare il traffico
                    della banca dati AISr e AISdt. I dati verranno conservati
                    nei nostri server. Essi saranno accessibili unicamente
                    agli amministratori del sito e non verranno condivisi con
                    terze parti. La raccolta e il trattamento dei dati
                    rispettano l'Art. 6, par. 1 let. b del Regolamento
                    generale europeo relativo alla protezione dei dati
                    personali delle persone fisiche (GDPR){" "}
                    <a href="https://gdpr-info.eu/art-6-gdpr/">
                      Art. 6 GDPR – Lawfulness of processing - General Data
                      Protection Regulation (GDPR)
                    </a>
                  </div>
                </div>

                <div className="download-button">
                  <button type="submit">
                    Accettare per procedere al download
                  </button>
                </div>
              </div>
            </div>
          )}
          </form>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ModalDownload;
