import React from "react";

function ModalMetadata({ lang }) {
  if (lang === "en") {
    return (
      <div className="window-content en">
        <h2>METADATA</h2>
        <p><u>1 &ndash; Breil/Brigels (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 65; profession: teacher; parents\' place of origin: Breil/Brigels; no long stays in other city.<br />Environment: schoolroom<br />Note: passive knowledge (percent on all responses): 0,9%</p>
<p><u>3 &ndash; Pitasch (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 66; profession: mechanic; electrician; parents\' place of origin: Pitasch; no long stays in other city.<br />Environment: private internal room; terrace<br />Note: the first part of the interview was conducted in an external environment; passive knowledge: 1.5%</p>
<p><u>5 &ndash; Domat/Ems (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 81; profession: teacher in a professional school; fathers\' place of origin: Domat/Ems, mothers\' place of origin: Zizers; short-term stays in Bern and Tiefencastel.<br />Environment: private internal room<br />Note: passive knowledge: 1%</p>
<p><u>7 &ndash; Ardez (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 90; profession: merchant; parents\' place of origin: Ardez; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 0,9%</p>
<p><u>9 &ndash; Ramosch (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 65; profession: farmer; fathers\' place of origin: Ftan; mothers\' place of origin: Pontresina; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 0,8%</p>
<p><u>10 &ndash; Camischollas (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 79; profession: blacksmith; parents\' place of origin: Camischollas; long-term stays in Winterthur.<br />Environment: private internal room<br />Note: passive knowledge: 2,9%</p>
<p><u>11 &ndash; Surrein (Sumvitg) (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 69; profession: teacher; parents\' place of origin: Surrein (Sumvitg); long-term stays in Chur, Landquart and Valera.<br />Environment: private internal room<br />Note: passive knowledge: 1,5%</p>
<p><u>13 &ndash; Vrin (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 53; profession: civil status official; parents\' place of origin: Vrin; no long stays in other city.<br />Environment: council hall <br />Note: passive knowledge: 0.7%</p>
<p><u>14 &ndash; Pr&auml;z (Canton of Grisons, Switzerland)</u><br />1st Informant: M; age: 79; profession: building draftsman; parents\' place of origin: Pr&auml;z; long-term stays in Chur.<br />2nd Informant: M; age: 54; profession: teacher; farmer; fathers\' place of origin: Pr&auml;z; mothers\' place of origin: Domleschg; short-term stays in the German-speaking part of Switzerland.<br />Environment: hotel lounge; terrace<br />Note: a part of the interview was conducted in an external environment; frequent background noises; passive knowledge: 18,4%</p>
<p><u>15 &ndash; Maton (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 73; profession: farmer; fathers\' place of origin: Wergenstein, mothers\' place of origin: Donat; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 2,5%</p>
<p><u>16 &ndash; Scharans (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 78; profession: carpenter; parents\' place of origin: Scharans; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 11,4%</p>
<p><u>17 &ndash; Lantsch/Lenz (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 62; profession: teacher; parents\' place of origin: Lantsch/Lenz; long-term stays in Vaz.<br />Environment: schoolroom<br />Note: passive knowledge: 2.8%</p>
<p><u>19 &ndash; Zernez (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 69; profession: typographer; fathers\' place of origin: Vn&agrave;, mothers\' place of origin: Zernez; short-term stays in Chur and Zurich.<br />Environment: hotel lounge<br />Note: passive knowledge: 3,8%</p>
<p><u>22 &ndash; Olivone (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 73; profession: secretary; parents\' place of origin: Osco; no long stays in other city<br />Environment: private internal room<br />Note: passive knowledge: 1,9%</p>
<p><u>25 &ndash; Reams/Riom (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 70; profession: telephonist PTT; telecommunications employee; parents\' place of origin: Reams/Riom; long-term stays in Chur.<br />Environment: private internal room<br />Note: passive knowledge: 0.5%</p>
<p><u>27 &ndash; Latsch (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 69; profession: technician in building design; fathers\' place of origin: Latsch; mothers\' place of origin: Germany; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 2,8%</p>
<p><u>28 &ndash; Zuoz (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 67; profession: fiduciary; fathers\' place of origin: Zuoz; mothers\' place of origin: Wil (SG); long-term stays in Zurich.<br />Environment: council hall <br />Note: passive knowledge: 0,9%</p>
<p><u>29 &ndash; Santa Maria (Canton of Grisons, Switzerland)</u><br />Informant: F; age: 60; profession: teacher; parents\' place of origin: Santa Maria; short term stays in Chur and Sankt Gallen.<br />Environment: private internal room<br />Note: passive knowledge: 0,3%</p>
<p><u>31 &ndash; Osco (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 76; profession: doctor; parents\' place of origin: Osco; long-term stays in Lugano.<br />Environment: public town hall<br />Note: passive knowledge: 0,3%</p>
<p><u>32 &ndash; Chironico (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 38; profession: insurer; fathers\' place of origin: Chironico, mothers\' place of origin: Airolo; short-term stays in Canada and German Switzerland.<br />Environment: outdoor gazebo<br />Note: passive knowledge: 0,8%</p>
<p><u>35 &ndash; Bivio (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 71; profession: host; parents\' place of origin: Bivio; no long stays in other city.<br />Environment: hotel hall<br />Note: passive knowledge: 0,1%</p>
<p><u>41 &ndash; Cavergno (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 64; profession: teacher in a professional school; parents\' place of origin: Cavergno; commuter in Ticino.<br />Environment: private internal room<br />Note: passive knowledge: 1,9%</p>
<p><u>42 &ndash; Sonogno (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 58; profession: farmer; fathers\' place of origin: Sonogno, mothers\' place of origin: Lucerna; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 1%</p>
<p><u>44 &ndash; Mesocco (Canton of Grisons, Switzerland)</u><br />Informant: F; age: 76; profession: homemaker; fathers\' place of origin: Starleggia / Mesocco, mothers\' place of origin: Soazza; long-term stays in Zurich.<br />Environment: private internal room<br />Note: passive knowledge: 3,1%</p>
<p><u>45 &ndash; Soglio (Canton of Grisons, Switzerland)</u><br />Informant: F; age: 72; profession: teacher / employed in the hotel industry; parents\' place of origin: Soglio; long-term stays in Engadina.<br />Environment: private internal room<br />Note: passive knowledge: 6%</p>
<p><u>46 &ndash; Coltura; Stampa (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 62; profession: butcher; parents\' place of origin: Coltura; short-term stays in Sankt Gallen.<br />Environment: private internal room<br />Note: passive knowledge: 4,3%</p>
<p><u>47 &ndash; Sils Maria (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 77; profession: ex professional skier; hotel keeper; fathers\' place of origin: Fex; mothers\' place of origins: Sils Maria; around the world between 1960 and 1970 for competitive activities; after that always in Sils Maria.<br />Environment: hotel lounge<br />Note: passive knowledge: 0,5%</p>
<p><u>50 &ndash; Cimalmotto; Campo (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 69; profession: typographer, postman; fathers\' place of origin: Niva, mothers\' place of origin: Cimalmotto; long-term stays in Locarno.<br />Environment: private internal room<br />Note: passive knowledge: 0,8%</p>
<p><u>51 &ndash; Vergeletto (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 71; profession: gamekeeper; parents\' place of origin: Vergeletto; short-term stays in Chiasso, Indemini, Gandria and Locarno.<br />Environment: council hall <br />Note: passive knowledge: 2,5%</p>
<p><u>52 &ndash; Aurigeno (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 71; profession: civil engineer; parents\' place of origin: Aurigeno; long-term stays in Locarno.<br />Environment: public hall<br />Note: passive knowledge: 3%</p>
<p><u>53 &ndash; Prosito (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 65; profession: employed in Swiss railways; fathers\' place of origin: Bergamo, mothers\' place of origin: Prosito; short-term stays in German Switzerland.<br />Environment: CDE (Centro di dialettologia ed etnografia di Bellinzona)<br />Note: passive knowledge: 1,3%</p>
<p><u>58 &ndash; Poschiavo (Canton of Grisons, Switzerland)</u><br />Informant: M; age: 77; profession: electrical engineer; parents\' place of origin: Poschiavo; long-term stays in Bern and Chur.<br />Environment: private internal room<br />Note: passive knowledge: 2,5%</p>
<p><u>70 &ndash; Indemini (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 77; profession: administrator; parents\' place of origin: Indemini; long-term stays in Locarno.<br />Environment: private internal room<br />Note: passive knowledge: 1,3%</p>
<p><u>71 &ndash; Breno (Canton of Ticino, Switzerland)</u><br />Informant: F; age: 85; profession: seamstress; parents\' place of origin: Breno; short-term stays in Massagno.<br />Environment: private internal room<br />Note: passive knowledge: 0,6%</p>
<p><u>73 &ndash; Corticiasca (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 73; profession: site manager; parents\' place of origin: Corticiasca; no long stays in other city.<br />Environment: private internal room<br />Note: passive knowledge: 0,2%</p>
<p><u>93 &ndash; Ligornetto (Canton of Ticino, Switzerland)</u><br />Informant: M; age: 86; profession: coachbuilder; parents\' place of origin: Ligornetto; no long stays in other city.<br />Environment: private internal room (underground floor)<br />Note: passive knowledge: 1,7%</p>
<p><u>139 &ndash; Galliate (Province of Novara, Piedmont, Italy)</u><br />Informant: M; age: 79; profession: chemical expert; parents\' place of origin:; no long stays in other cities.<br />Environment: city library, private internal room<br />Note: passive knowledge: 2,2%</p>
<p><u>236 &ndash; Branzi (Province of Bergamo, Lombardy, Italy)</u><br />Informant: F; age: 61; profession: teacher (Italian); parents\' place of origin: Branzi; short-term stays in Valle Brembana.<br />Environment: private internal room<br />Note: passive knowledge: 5,1%</p>
<p><u>237 &ndash; Gromo (Province of Bergamo, Lombardy, Italy)</u><br />Informant: F; age: 82; profession: florist; parents\' place of origin: Gromo; Born in Bergamo, lived there until the age of 7.<br />Environment: private internal room<br />Note: passive knowledge: 3,1%</p>
<p><u>242 &ndash; Como (Province of Como, Lombardy, Italy)</u><br />Informant: M; age: 66; profession: pastry chef; fathers\' place of origin: Brunate; mothers\' place of origin: Molina; no long stays in other cities.<br />Environment: Office Famiglia Comasca<br />Note: passive knowledge: 0,2%</p>
<p><u>243 &ndash; Canzo (Province of Como, Lombardy, Italy)</u><br />Informant: M; age: 64; profession: textile expert; parents\' place of origin: Canzo; no long stays in other cities.<br />Environment: private internal room<br />Note: passive knowledge: 0,3%</p>
<p><u>245 &ndash; Stabello (Province of Bergamo, Lombardy, Italy)</u><br />Informant: M; age: 57; profession: teacher; fathers\' place of origin: Sedrina (moved to Stabello at age 3), mothers\' place of origin: Brembilla (adoptive family from Stabello); long-term stays in Ginevra (1 year), Sassari and Rome (military service).<br />Environment: private internal room<br />Note: passive knowledge: 0,7%</p>
<p><u>246 &ndash; Bergamo (Magnago) (Province of Bergamo, Lombardy, Italy)</u><br />Informant: F; age: 89; profession: teacher; fathers\' place of origin: Mornico (moved to Bergamo at the age of 6), mothers\' place of origin: Villa d&rsquo;Ogna; no long stays in other cities.<br />Environment: private internal room<br />Note: passive knowledge: 2%</p>
<p><u>247 &ndash; Monasterolo del Castello (Province of Bergamo, Lombardy, Italy)</u><br />Informant: F; age: 80; profession: homemaker; fathers\' place of origin: Monasterolo del Castello, mothers\' place of origin: Foresto Sparso/Monasterolo del Castello; long-term stay in San Giovanni Bianco (6 years).<br />Environment: private internal room<br />Note: passive knowledge: 3,2%</p>
<p><u>250 &ndash; Bienate (Province of Milano, Lombardy, Italy)</u><br />Informant: M; age: 70; profession: commercial agent; fathers\' place of origin: Mesero, mothers\' place of origin: Bienate; no long stays in other cities.<br />Environment: Office Proloco Bienate Magnago <br />Note: passive knowledge: 1,8%</p>
<p><u>254 &ndash; Martinengo (Province of Bergamo, Lombardy, Italy)</u><br />Informant: F; age: 82; profession: homemaker; parents\' place of origin: Martinengo; long-term stay in Milan (10 years).<br />Environment: town library<br />Note: passive knowledge: 1,8%</p>
<p><u>261 &ndash; Milano (Province of Milano, Lombardy, Italy)</u><br />Informant: M; age: 65; profession: teacher; parents\' place of origin: Milano; no long stays in other cities.<br />Environment: private internal room<br />Note: passive knowledge: 1,4%</p>
      </div>
    );
  } else if (lang === "it") {
    return (
      <div className="window-content it">
        <h2>METADATI</h2>
        <p><u>1 &ndash; Breil/Brigels (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 65 anni; professione: docente scuola elementare; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: aula scolastica<br />Note: competenza passiva (percentuale sul totale delle risposte): 0,9%</p>
<p><u>3 &ndash; Pitasch (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 66 anni; professione: disegnatore e costruttore meccanico; elettricista; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna; terrazza<br />Note: la prima parte dell&rsquo;intervista &egrave; stata condotta in ambiente esterno; competenza passiva: 1,5%</p>
<p><u>5 &ndash; Domat/Ems (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 81 anni; professione: docente scuola professionale; padre del luogo, madre: Zizers; ha vissuto 2 anni a Berna e 2 a Tiefencastel.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 1%</p>
<p><u>7 &ndash; Ardez (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 90 anni; professione: commerciante; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,9%</p>
<p><u>9 &ndash; Ramosch (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 65 anni; professione: contadino; padre: Ftan; madre: Pontresina; &egrave; nato e cresciuto a Ramosch dove ha sempre vissuto. <br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,8%</p>
<p><u>10 &ndash; Camischollas (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 79 anni; professione: fabbro ferraio; genitori del luogo; ha vissuto 7 anni a Winterthur per lavoro.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 2,9%</p>
<p><u>11 &ndash; Surrein (Sumvitg) (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 69 anni; professione: docente scuola elementare; genitori del luogo; cresciuto a Surrein, dove &egrave; tornato nel 1980 dopo aver vissuto 16 tra Coira, Landquart e Valera.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 1,5%</p>
<p><u>13 &ndash; Vrin (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 53 anni; professione: ufficiale dello stato civile a Ilanz; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: sala comunale<br />Note: competenza passiva: 0,7%</p>
<p><u>14 &ndash; Pr&auml;z (Canton Grigioni, Svizzera)</u><br />1&deg; Informatore: M; et&agrave;: 79 anni; professione: disegnatore edile; genitori del luogo; ha vissuto molti anni a Coira.<br />2&deg; Informatore: et&agrave;: 54 anni; professione: docente scuola elementare; contadino; padre del luogo: madre: Domigliasca; ha vissuto qualche anno nella Svizzera tedesca.<br />Luogo dell&rsquo;intervista: salone d&rsquo;albergo; terrazza<br />Note: una parte dell&rsquo;intervista &egrave; stata condotta in ambiente esterno; frequenti rumori di sottofondo; competenza passiva: 18,4%</p>
<p><u>15 &ndash; Maton (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 73 anni; professione: contadino; padre: Wergenstein, madre: Donat; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 2,5%</p>
<p><u>16 &ndash; Scharans (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 78 anni; professione: falegname; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 11,4%</p>
<p><u>17 &ndash; Lantsch/Lenz (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 62 anni; professione: docente scuola elementare; genitori del luogo; ha vissuto 12 anni a Vaz per lavoro.<br />Luogo dell&rsquo;intervista: aula scolastica<br />Note: competenza passiva: 3,1%</p>
<p><u>19 &ndash; Zernez (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 69 anni; professione: tipografo; padre: Vn&agrave;, madre del luogo; ha vissuto 3 anni a Zurigo e 2 a Coira.<br />Luogo dell&rsquo;intervista: salone d&rsquo;albergo<br />Note: ha lavorato 12 anni come promotore per la Lia Rumantscha; possibili rumori di sottofondo; competenza passiva: 3,8%</p>
<p><u>22 &ndash; Olivone (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 73 anni; professione: segretario; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 1,9%</p>
<p><u>25 &ndash; Reams/Riom (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 70 anni; professione: telefonista PTT; impiegato telecomunicazioni; genitori del luogo; ha vissuto molti anni a Coira per lavoro; dal 2000 residente a Riom, dove &egrave; cresciuto.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,5%</p>
<p><u>27 &ndash; Latsch (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 69 anni; professione: tecnico in progettazione edile; padre del luogo; madre d&rsquo;origine tedesca; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 2,8%</p>
<p><u>28 &ndash; Zuoz (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 67 anni; professione: fiduciario; padre del luogo; madre di Wil (SG); ha vissuto per 13 anni a Zurigo.<br />Luogo dell&rsquo;intervista: sala comunale<br />Note: competenza passiva: 0,9%</p>
<p><u>29 &ndash; Santa Maria (Canton Grigioni, Svizzera)</u><br />Informatore: F; et&agrave;: 60 anni; professione: docente scuola elementare; genitori del luogo; ha vissuto 3 anni a Coira e 4 a San Gallo, dal 1985 risiede a Santa Maria.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,3%</p>
<p><u>31 &ndash; Osco (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 76 anni; professione: medico; genitori del luogo; ha vissuto per lunghi periodi a Lugano.<br />Luogo dell&rsquo;intervista: sala comunale pubblica<br />Note: competenza passiva: 0,3%</p>
<p><u>32 &ndash; Chironico (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 38 anni; professione: assicuratore; padre del luogo, madre: Airolo; ha vissuto sei mesi in Canada e svolto la leva militare nella Svizzera tedesca.<br />Luogo dell&rsquo;intervista: gazebo esterno<br />Note: competenza passiva: 0,8%</p>
<p><u>35 &ndash; Bivio (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 71 anni; professione: albergatore; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: salone d&rsquo;albergo<br />Note: competenza passiva: 0,1%</p>
<p><u>41 &ndash; Cavergno (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 64 anni; professione: docente scuola professionale; genitori del luogo; pendolare in Ticino per lavoro.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 1,9%</p>
<p><u>42 &ndash; Sonogno (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 58 anni; professione: contadino; padre del luogo, madre: Lucerna; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna/studio<br />Note: competenza passiva: 1%</p>
<p><u>44 &ndash; Mesocco (Canton Grigioni, Svizzera)</u><br />Informatore: F; et&agrave;: 76 anni; professione: casalinga; padre: Starleggia / Mesocco, madre: Soazza; ha vissuto 3 anni a Zurigo.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 3,1%</p>
<p><u>45 &ndash; Soglio (Canton Grigioni, Svizzera)</u><br />Informatore: F; et&agrave;: 72 anni; professione: maestra / impiegata nel settore alberghiero; genitori del luogo; ha vissuto per 20 anni in Engadina.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 6%</p>
<p><u>46 &ndash; Coltura; Stampa (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 62 anni; professione: macellaio; genitori del luogo; ha vissuto qualche anno a San Gallo.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 4,3%</p>
<p><u>47 &ndash; Sils Maria (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 77 anni; professione: ex sciatore professionista, albergatore; padre di Fex; madre di Sils Maria; dal 1960 al 1970 ha girato il mondo grazie all&rsquo;attivit&agrave; agonistica; dal 1970 risiede nuovamente nel luogo di nascita.<br />Luogo dell&rsquo;intervista: salone d&rsquo;albergo<br />Note: competenza passiva: 0,5%</p>
<p><u>50 &ndash; Cimalmotto; Campo (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 69 anni; professione: tipografo, postino; padre: Niva, madre del luogo; ha vissuto 20 anni a Locarno per lavoro.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,8%</p>
<p><u>51 &ndash; Vergeletto (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 71 anni; professione: guardiacaccia; genitori del luogo; ha vissuto per brevi periodi a Chiasso, Indemini, Gandria e Locarno.<br />Luogo dell&rsquo;intervista: sala consiliare<br />Note: competenza passiva: 2,5%</p>
<p><u>52 &ndash; Aurigeno (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 71 anni; professione: ingegnere civile; genitori del luogo; ha vissuto lunghi periodi a Locarno per lavoro.<br />Luogo dell&rsquo;intervista: sala del patriziato<br />Note: competenza passiva: 3%</p>
<p><u>53 &ndash; Prosito (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 65 anni; professione: impiegato nelle ferrovie; padre: Bergamo, madre del luogo; ha vissuto qualche anno nella Svizzera tedesca.<br />Luogo dell&rsquo;intervista: sala CDE (Centro di dialettologia ed etnografia di Bellinzona)<br />Note: competenza passiva: 1,3%</p>
<p><u>58 &ndash; Poschiavo (Canton Grigioni, Svizzera)</u><br />Informatore: M; et&agrave;: 77 anni; professione: ingegnere elettrotecnico; genitori del luogo; ha vissuto 3 anni a Berna e poi per molto tempo a Coira.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 2,5%</p>
<p><u>70 &ndash; Indemini (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 77 anni; professione: amministratore; genitori del luogo; ha vissuto a Locarno per lavoro.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 1,3%</p>
<p><u>71 &ndash; Breno (Canton Ticino, Svizzera)</u><br />Informatore: F; et&agrave;: 85 anni; professione: sarta; genitori del luogo; ha vissuto per qualche anno a Massagno (Lugano) per lavoro.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,6%</p>
<p><u>73 &ndash; Corticiasca (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 73 anni; professione: tecnico edile; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,2%</p>
<p><u>93 &ndash; Ligornetto (Canton Ticino, Svizzera)</u><br />Informatore: M; et&agrave;: 86 anni; professione: carrozziere; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita.<br />Luogo dell&rsquo;intervista: stanza privata interna (piano interrato)<br />Note: competenza passiva: 1,7%</p>
<p><u>139 &ndash; Galliate (Lombardia, prov. MI, Italia)</u><br />Informatore: M; et&agrave;: 79 anni; professione: perito chimico, agricoltore; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita. <br />Luogo dell&rsquo;intervista: Biblioteca di Galliate, stanza privata interna<br />Note: competenza passiva: 2,2%</p>
<p><u>236 &ndash; Branzi (Lombardia, prov. BG, Italia)</u><br />Informatore: F; et&agrave;: 61 anni; professione: insegnante (italiano); genitori del luogo; ha sempre vissuto in Val Brembana, pur spostandosi in altri paesi diversi da Branzi. <br />Luogo dell&rsquo;intervista: stanza privata interna <br />Note: competenza passiva: 5,1%</p>
<p><u>237 &ndash; Gromo (Province of Bergamo, Lombardy, Italy)</u><br />Informatore: F; et&agrave;: 82 anni; professione: fiorista; genitori del luogo; &egrave; nata a Bergamo e si &egrave; trasferita a Gromo all&rsquo;et&agrave; di sette anni. . <br />Luogo dell&rsquo;intervista: stanza privata interna <br />Note: competenza passiva: 3,1%</p>
<p><u>242 &ndash; Como (Province of Como, Lombardy, Italy)</u><br />Informatore: M; et&agrave;: 66 anni; professione: pasticciere, autore di teatro; padre di Brunate; madre di Molina; nessun lungo periodo lontano dal luogo di nascita. <br />Luogo dell&rsquo;intervista: Sede Famiglia Comasca<br />Note: competenza passiva: 0,2%</p>
<p><u>243 &ndash; Canzo (Province of Como, Lombardy, Italy)</u><br />Informatore: M; et&agrave;: 64 anni; professione: assistente tessile; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita. <br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 0,3%</p>
<p><u>245 &ndash; Stabello (Province of Bergamo, Lombardy, Italy)</u><br />Informatore: M; et&agrave;: 57 anni; professione: insegnante; padre: Sedrina (si &egrave; trasferito a Stabello quando aveva 3 anni); madre: Brembilla (la sua famiglia adottiva &egrave; di Stabello); ha vissuto per 1 anno a Ginevra e ha fatto il servizio militare a Sassari e Roma. <br />Luogo dell&rsquo;intervista: stanza privata interna <br />Note: competenza passiva: 0,7%</p>
<p><u>246 &ndash; Bergamo (Lombardia, prov. BG, Italia)</u><br />Informatore: F; et&agrave;: 89 anni; professione: insegnante; padre: Mornico, trasferitosi poi a Bergamo all&rsquo;et&agrave; di sei anni.; madre: Villa d&rsquo;Ogna; nessun lungo periodo lontano dal luogo di nascita. <br />Luogo dell&rsquo;intervista: stanza privata interna <br />Note: competenza passiva: 2%</p>
<p><u>247 &ndash; Monasterolo del Castello (Lombardia, prov. BG, Italia)</u><br />Informatore: F; et&agrave;: 80 anni; professione: casalinga; padre del luogo; madre: Foresto Sparso/Monasterolo del Castello; ha vissuto per 6 anni a San Giovanni Bianco (prov. BG). <br />Luogo dell&rsquo;intervista: stanza privata interna <br />Note: competenza passiva: 3,2%</p>
<p><u>250 &ndash; Bienate (Magnago) (Lombardia, prov. MI, Italia)</u><br />Informatore: M; et&agrave;: 70 anni; professione: agente di commercio; madre del luogo; padre: Mesero; nessun lungo periodo lontano dal luogo di nascita. <br />Luogo dell&rsquo;intervista: Proloco Bienate Magnago <br />Note: competenza passiva: 1,8%</p>
<p><u>254 &ndash; Martinengo (Lombardia, prov. BG, Italia)</u><br />Informatore: F; et&agrave;: 82 anni; professione: casalinga; genitori del luogo; ha vissuto per una decina di anni a Milano. <br />Luogo dell&rsquo;intervista: Biblioteca di Martinengo <br />Note: competenza passiva: 1,8%</p>
<p><u>261 &ndash; Milano (Province of Milano, Lombardy, Italy)</u><br />Informatore: M; et&agrave;: 65 anni; professione: insegnante; genitori del luogo; nessun lungo periodo lontano dal luogo di nascita. <br />Luogo dell&rsquo;intervista: stanza privata interna<br />Note: competenza passiva: 1,4%</p>
      </div>
    );
  }
}

export default ModalMetadata;
