import React from "react";
import { CSSTransition } from "react-transition-group";
import iconMenu from "../svg/icon__menu.svg";

function Menu({ isActive, labels, lang, onCheck, onToggle }) {
  function handleClick(e, name) {
    e.preventDefault();
    onCheck(name);
    onToggle();
  }
  return (
    <div className="menu-wrapper">
      <button
        className="btn-menu"
        onClick={() => onToggle()}
        onMouseUp={() => (isActive ? document.activeElement.blur() : null)}
      >
        <img src={iconMenu} alt="Menu" />
      </button>
      <CSSTransition
        in={isActive}
        timeout={120}
        classNames="menu"
        unmountOnExit
      >
        <div className="menu-drop">
          <a href="/" onClick={(e) => handleClick(e, "The Database")}>
            {lang === "en" ? labels[0][0] : labels[0][1]}
          </a>
          <a href="/" onClick={(e) => handleClick(e, "How To Use")}>
            {lang === "en" ? labels[1][0] : labels[1][1]}
          </a>
          <a href="/" onClick={(e) => handleClick(e, "Questionnaire")}>
            {lang === "en" ? labels[2][0] : labels[2][1]}
          </a>
          <a href="/" onClick={(e) => handleClick(e, "Metadata")}>
            {lang === "en" ? labels[3][0] : labels[3][1]}
          </a>
          <a href="/" onClick={(e) => handleClick(e, "Research Team")}>
            {lang === "en" ? labels[4][0] : labels[4][1]}
          </a>
          <a href="/" onClick={(e) => handleClick(e, "Credits")}>
            {lang === "en" ? labels[5][0] : labels[5][1]}
          </a>
          {/* <a
            className="download"
            href="https://cloud.ibros.ch/s/Fe25FccbpkqB8mk/download"
          >
            {lang === "en" ? labels[6][0] : labels[6][1]}
          </a> */}
        </div>
      </CSSTransition>
    </div>
  );
}

export default Menu;
