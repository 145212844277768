import React, { useState } from "react";
import iconDropdown from "../svg/icon__dropdown.svg";
import { CSSTransition } from "react-transition-group";

function DropdownPeriod({
  label,
  isActive,
  pastTranscription,
  onToggle,
  onValueChange,
  onTranscriptionChange
}) {
  const [value, setValue] = useState();

  return (
    <div className="dropdown-wrapper">
      <button
        className="btn-dropdown"
        onClick={() => onToggle()}
        onMouseUp={() => (isActive ? document.activeElement.blur() : null)}
      >
        <div className="label">{value ? value : label}</div>
        <img src={iconDropdown} alt="Switch to map view" />
      </button>
      <CSSTransition
        in={isActive}
        timeout={120}
        classNames="dropdown"
        unmountOnExit
      >
        <div className="dropdown-drop">
          <div
            className="item"
            key={0}
            onClick={() => {
              setValue(null);
              onValueChange("");
            }}
          >
            All
          </div>
          <div
            className="item period"
            key={1}
            onClick={() => {
              setValue("Present");
              onValueChange("present");
            }}
          >
            Present
            <div className="transcription active">IPA</div>
          </div>
          <div
            className="item period"
            key={2}
            onClick={() => {
              setValue("Past");
              onValueChange("past");
            }}
          >
            Past
            <div className="transcription-group">
              <div
                className={
                  "transcription " + (pastTranscription ? "alt" : "active")
                }
                onClick={e => {
                  e.stopPropagation();
                  onTranscriptionChange(0);
                }}
              >
                AIS
              </div>
              <div
                className={
                  "transcription " + (pastTranscription ? "active" : "alt")
                }
                onClick={e => {
                  e.stopPropagation();
                  onTranscriptionChange(1);
                }}
              >
                IPA
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default DropdownPeriod;
