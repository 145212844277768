import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

function Map({ data, locations, pastTranscription }) {
  const mapRef = useRef(null);
  const clusterRef = useRef(null);
  const [prevTranscription, setPrevTranscription] = useState(pastTranscription);

  useEffect(() => {
    mapRef.current = L.map("map", {
      center: [46.5430035, 7.7980188],
      zoom: 6,
      zoomSnap: 0.5,
      layers: [
        L.tileLayer(
          "https://api.mapbox.com/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            id: "styles/v1/sedles/cjxrm5cw31c5s1co3d9wjk341",
            accessToken:
              "pk.eyJ1IjoiaWJyb3MiLCJhIjoiY2xmZWR3Y3lvMm9hcjNycGMyZmF5ajB4aSJ9.d31KH57FJYaawDwxxG36vg",
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          }
        )
      ]
    });
    clusterRef.current = L.markerClusterGroup({
      showCoverageOnHover: false,
      iconCreateFunction: function(cluster) {
        return L.divIcon({
          html: cluster.getChildCount(),
          className: "cluster small",
          iconSize: L.point(44, 44)
        });
      }
    });
    mapRef.current.addLayer(clusterRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let positions = [];
    let labels = [];
    let locationKeys = {};
    let i = 0;

    // find position and label for markers
    data.forEach(row => {
      const tempLocation = locations.find(obj => obj.id === row.location);
      if (tempLocation && tempLocation.location) {
        if (row.period === "past") {
          locationKeys[row.location] = i;
          const lat = tempLocation.location
            .split(" ")
            .slice(-2)[1]
            .slice(0, -1);

          const lng = tempLocation.location
            .split(" ")
            .slice(-2)[0]
            .slice(1);
          positions.push([lat, lng]);
          labels.push([
            tempLocation.ais_id,
            pastTranscription ? row.ipa : row.ais
          ]);
          i++;
        } else if (row.period === "present" && row.location in locationKeys) {
          labels[locationKeys[row.location]].push(
            row.audio !== null
              ? "<div class='play' onClick='(new Audio(\"" +
                  row.audio +
                  "\")).play()'><svg width='20' height='24' viewBox='2 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18.1829 11.6527C18.4516 11.8063 18.4516 12.1937 18.1829 12.3473L7.19845 18.6241C6.93179 18.7765 6.6 18.584 6.6 18.2768L6.6 5.72318C6.6 5.41605 6.93179 5.2235 7.19846 5.37588L18.1829 11.6527Z' stroke-width='1.2'/></svg>" +
                  row.ipa +
                  "</div>"
              : "<span style='color: #47acb2'>" + row.ipa + "</span>"
          );
        } else if (row.period === "present") {
          locationKeys[row.location] = i;
          const lat = tempLocation.location
            .split(" ")
            .slice(-2)[1]
            .slice(0, -1);

          const lng = tempLocation.location
            .split(" ")
            .slice(-2)[0]
            .slice(1);
          positions.push([lat, lng]);
          labels.push([
            tempLocation.ais_id,
            row.audio !== null
              ? "<div class='play' onClick='(new Audio(\"" +
                row.audio +
                "\")).play()'><svg width='20' height='24' viewBox='2 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18.1829 11.6527C18.4516 11.8063 18.4516 12.1937 18.1829 12.3473L7.19845 18.6241C6.93179 18.7765 6.6 18.584 6.6 18.2768L6.6 5.72318C6.6 5.41605 6.93179 5.2235 7.19846 5.37588L18.1829 11.6527Z' stroke-width='1.2'/></svg>" +
                row.ipa +
                "</div>"
              : "<span style='color: #47acb2'>" + row.ipa + "</span>"
          ]);
          i++;
        }
      }
    });
    clusterRef.current.clearLayers();
    // add markers to cluster
    positions.forEach((el, i) => {
      clusterRef.current.addLayer(
        L.marker(
          { lat: el[0], lng: el[1] },
          {
            icon: new L.DivIcon({
              className: "map-marker-wrapper",
              html:
                "<div class='map-marker'>" +
                "<span style='color: #e5617d'>" +
                labels[i][0] +
                "&nbsp;&nbsp;&nbsp;</span>" +
                `<span ${labels[i][1] === '—' ? "style='color: #50c2c9'" : ""}>` +
                labels[i][1] +
                "&nbsp;&nbsp;&nbsp;</span>" +
                (labels[i].length === 3 ? labels[i][2] + "  " : "  ") +
                "</div>"
            })
          }
        )
      );
    });

    //fit all markers to the screen
    if (prevTranscription === pastTranscription) {
      let bounds;
      if (positions.length === 1) {
        bounds = L.latLngBounds([
          [
            (parseFloat(positions[0][0]) - 1).toString(),
            (parseFloat(positions[0][1]) + 1).toString()
          ],
          [
            (parseFloat(positions[0][0]) + 0.85).toString(),
            (parseFloat(positions[0][1]) - 1).toString()
          ]
        ]);
        mapRef.current.flyToBounds(bounds, {
          duration: 1,
          padding: [10, 20]
        });
      } else if (positions.length > 1) {
        bounds = L.latLngBounds(positions);
        mapRef.current.flyToBounds(bounds, {
          duration: 1,
          padding: [10, 20]
        });
      }
    } else setPrevTranscription(pastTranscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pastTranscription]);

  return <main id="map" />;
}

export default Map;
