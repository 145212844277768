import React from "react";

function ModalOurTeam({ lang }) {
  if (lang === "en") {
    return (
      <div className="window-content en">
        <div style={{width: '100%', display: 'flex'}}>
        <div style={{flex: '1', padding: '10px'}}>
            <h2><b>AIS, reloaded</b> (2016-2019)</h2>

<p><em>Gruppo di ricerca</em></p>
<p><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/loporcaro.html">Prof. Michele Loporcaro</a> (P.I.)<br/><a href="https://www.cl.uzh.ch/de/people/team/phonetics/schmid.html">Prof. Stephan Schmid</a> (co-P.I.)<br/>Diego Pescarini (fino al 31.01.18)<br/>Giulia Donzelli<br/>Stefano Negrinelli<br/>Chiara Zanini (dal 01.02.18)<br/><em>Collaboratori</em><br/>Camilla Bernardasci<br/>Andrea Bizzeti<br/>Claudia Cathomas<br/>Lisa Gasner<br/>Giovanni Manzari<br/>Adriano Salvi<br/><em>Collaboratori esterni</em><br/><a href="http://www3.pd.istc.cnr.it/navigais/">Graziano Tisato</a> (ISTC-CNR Padova)<br/>Dominique Dosch (Universit&auml;t Freiburg)<br/><em>Consulenti scientifici</em><br/><a href="https://www3.unifr.ch/directory/de/people/5030/30a27">Prof. Matthias Gr&uuml;nert</a> (Universit&auml;t Freiburg)<br/>Franco Lur&agrave; (CDE Bellinzona)<br/><a href="https://www.unibe.ch/universitaet/organisation/leitung_und_zentralbereich/vizerektorat_lehre/prof_dr_moretti_bruno/index_ger.html">Prof. Bruno Moretti</a> (Universit&auml;t Bern)<br/><em>Supporto tecnico</em><br/>UZH VideoLab UFSP<br/>UZH Phonogram Archives (<a href="https://www.phonogrammarchiv.uzh.ch/en.html">PAZ</a>)</p>

        </div>
        <div style={{flex: '1', padding: '10px'}}>
            <h2><b>AIS, the digital turn</b> (2021-2025)</h2>
            
<p><em>Gruppo di ricerca</em></p>
<p><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/loporcaro.html">Prof. Michele Loporcaro</a> (P.I.)<br/><a href="https://www.aliceidone.info/home">Alice Idone</a><br/><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/donzelli.html">Giulia Donzelli</a><br/><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/gasner.html">Lisa Gasner</a><br/><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/salvi.html">Adriano Salvi</a><br/><em>Collaboratori</em><br/>Fabio Ardolino<br/>Giovanni Manzari<br/><em>Collaboratori esterni</em><br/><a href="http://www3.pd.istc.cnr.it/navigais/">Graziano Tisato</a> (ISTC-CNR Padova)<br/>Daniel Wanitsch (<a href="https://www.ibros.ch/">iBros</a>)<br/><em>Consulenti scientifici</em><br/>Prof. <a href="https://www.cl.uzh.ch/de/people/team/phonetics/schmid.html">Stephan Schmid</a> (Universit&agrave; di Zurigo)<br/>Prof. <a href="https://bcl.cnrs.fr/rubrique220">Diego Pescarini</a> (CNRS; Universit&eacute; C&ocirc;te d&rsquo;Azur, Nice)<br/>Prof. Giovanni Bonfadini (Prof. emerito, Universit&agrave; degli Studi di Milano)<br/>Prof. <a href="https://www.romanistik.hu-berlin.de/de/institut/mitarbeitende/filipponio">Lorenzo Filipponio</a> (Humboldt-Universit&auml;t Berlin)<br/><em>Supporto tecnico</em><br/>UZH VideoLab<br/>UZH Phonogram Archives (<a href="https://www.phonogrammarchiv.uzh.ch/en.html">PAZ</a>)<br/>UZH Linguistic Research Infrastructure (<a href="https://www.liri.uzh.ch/en.html">LiRI</a>)<br/>UZH URPP &ldquo;Language and Space&rdquo; (<a href="https://www.spur.uzh.ch/en.html">SpuR</a>)</p>
        </div>
    </div>
      </div>
    );
  } else if (lang === "it") {
    return (
      <div className="window-content it">
        <div style={{width: '100%', display: 'flex'}}>
        <div style={{flex: '1', padding: '10px'}}>
            <h2><b>AIS, reloaded</b> (2016-2019)</h2>
<p><em>Research team</em></p>
<p><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/loporcaro.html">Prof. Michele Loporcaro</a> (P.I.)<br/><a href="https://www.cl.uzh.ch/de/people/team/phonetics/schmid.html">Prof. Stephan Schmid</a> (co-P.I.)<br/>Diego Pescarini (until 31.01.18)<br/>Giulia Donzelli<br/>Stefano Negrinelli<br/>Chiara Zanini (from 01.02.18)<br/><em>Collaborators</em><br/>Camilla Bernardasci<br/>Andrea Bizzeti<br/>Claudia Cathomas<br/>Lisa Gasner<br/>Giovanni Manzari<br/>Adriano Salvi<br/><em>External collaborators</em><br/><a href="http://www3.pd.istc.cnr.it/navigais/">Graziano Tisato</a> (ISTC-CNR Padova)<br/>Dominique Dosch (Universit&auml;t Freiburg)<br/><em>Scientific consultants</em><br/><a href="https://www3.unifr.ch/directory/de/people/5030/30a27">Prof. Matthias Gr&uuml;nert</a> (Universit&auml;t Freiburg)<br/>Franco Lur&agrave; (CDE Bellinzona)<br/><a href="https://www.unibe.ch/universitaet/organisation/leitung_und_zentralbereich/vizerektorat_lehre/prof_dr_moretti_bruno/index_ger.html">Prof. Bruno Moretti</a> (Universit&auml;t Bern)<br/><em>Technical support</em><br/>UZH VideoLab UFSP<br/>UZH Phonogram Archives (<a href="https://www.phonogrammarchiv.uzh.ch/en.html">PAZ</a>)</p>
        </div>
        <div style={{flex: '1', padding: '10px'}}>
            <h2><b>AIS, the digital turn</b> (2021-2025)</h2>
<p><em>Research team</em></p>
<p><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/loporcaro.html">Prof. Michele Loporcaro</a> (P.I.)<br/><a href="https://www.aliceidone.info/home">Alice Idone</a><br/><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/donzelli.html">Giulia Donzelli</a><br/><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/gasner.html">Lisa Gasner</a><br/><a href="https://www.rose.uzh.ch/de/seminar/wersindwir/mitarbeitende/salvi.html">Adriano Salvi</a><br/><em>Collaborators</em><br/>Fabio Ardolino<br/>Giovanni Manzari<br/><em>External collaborators</em><br/><a href="http://www3.pd.istc.cnr.it/navigais/">Graziano Tisato</a> (ISTC-CNR Padova)<br/>Daniel Wanitsch (<a href="https://www.ibros.ch/">iBros</a>)<br/><em>Scientific consultants</em><br/>Prof. <a href="https://www.cl.uzh.ch/de/people/team/phonetics/schmid.html">Stephan Schmid</a> (University of Zurich)<br/>Prof. <a href="https://bcl.cnrs.fr/rubrique220">Diego Pescarini</a> (CNRS; Universit&eacute; C&ocirc;te d&rsquo;Azur, Nice)<br/>Prof. Giovanni Bonfadini (Prof. emeritus, University of Milan)<br/>Prof. <a href="https://www.romanistik.hu-berlin.de/de/institut/mitarbeitende/filipponio">Lorenzo Filipponio</a> (Humboldt University Berlin)<br/><em>Technical support</em><br/>UZH VideoLab<br/>UZH Phonogram Archives (<a href="https://www.phonogrammarchiv.uzh.ch/en.html">PAZ</a>)<br/>UZH Linguistic Research Infrastructure (<a href="https://www.liri.uzh.ch/en.html">LiRI</a>)<br/>UZH URPP &ldquo;Language and Space&rdquo; (<a href="https://www.spur.uzh.ch/en.html">SpuR</a>)</p>

        </div>
    </div>
      </div>
    );
  }
}

export default ModalOurTeam;
