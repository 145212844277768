import React from "react";

function ModalCredits({ lang }) {
  if (lang === "en") {
    return (
      <div className="window-content en">
        <h2>CREDITS</h2>
        <p>Michele Loporcaro, Stephan Schmid, Diego Pescarini, Graziano Tisato, Giulia Donzelli, Lisa Gasner, Alice Idone, Stefano Negrinelli, Adriano Salvi, Chiara Zanini (2022). <em>AIS, reloaded</em> (AISr). University of Zurich. <a href="https://www.ais-reloaded.uzh.ch/">https://www.ais-reloaded.uzh.ch/</a></p>
      </div>
    );
  } else if (lang === "it") {
    return (
      <div className="window-content it">
        <h2>COME CITARE</h2>
		<p>Michele Loporcaro, Stephan Schmid, Diego Pescarini, Graziano Tisato, Giulia Donzelli, Lisa Gasner, Alice Idone, Stefano Negrinelli, Adriano Salvi, Chiara Zanini (2022). <em>AIS, reloaded</em> (AISr). University of Zurich. <a href="https://www.ais-reloaded.uzh.ch/">https://www.ais-reloaded.uzh.ch/</a></p>
      </div>
    );
  }
}

export default ModalCredits;
