import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import ModalProject from "./ModalProject";
import ModalHowTo from "./ModalHowTo";
import ModalQuestionnaire from "./ModalQuestionnaire";
import ModalMetadata from "./ModalMetadata";
import ModalOurTeam from "./ModalOurTeam";
import ModalCredits from "./ModalCredits";

import "./Modal.scss";
import iconClose from "../svg/icon__close.svg";

function ModalMenu({
  isActive,
  current,
  labels,
  onClose,
  onMenuChange,
  onLangChange
}) {
  const [lang, setLang] = useState("en");

  return (
    <CSSTransition in={isActive} timeout={160} classNames="modal" unmountOnExit>
      <div
        className="modal"
        onClick={e => {
          if (e.target === document.getElementsByClassName("modal")[0])
            onClose();
        }}
      >
        <div className="window">
          <div className="head">
            <ul>
              <li onClick={() => onMenuChange("The Database")}>
                {lang === "en" ? labels[0][0] : labels[0][1]}
              </li>
              <li onClick={() => onMenuChange("How To Use")}>
                {lang === "en" ? labels[1][0] : labels[1][1]}
              </li>
              <li onClick={() => onMenuChange("Questionnaire")}>
                {lang === "en" ? labels[2][0] : labels[2][1]}
              </li>
              <li onClick={() => onMenuChange("Metadata")}>
                {lang === "en" ? labels[3][0] : labels[3][1]}
              </li>
              <li onClick={() => onMenuChange("Research Team")}>
                {lang === "en" ? labels[4][0] : labels[4][1]}
              </li>
              <li onClick={() => onMenuChange("Credits")}>
                {lang === "en" ? labels[5][0] : labels[5][1]}
              </li>
            </ul>
            <div className="control">
              <div className="languages">
                <div
                  className={"lang " + (lang === "en" ? "active" : "alt")}
                  onClick={() => {
                    setLang("en");
                    onLangChange("en");
                  }}
                >
                  EN
                </div>
                <div
                  className={"lang " + (lang === "it" ? "active" : "alt")}
                  onClick={() => {
                    setLang("it");
                    onLangChange("it");
                  }}
                >
                  IT
                </div>
              </div>
              <img src={iconClose} alt="Close icon" onClick={() => onClose()} />
            </div>
          </div>
          {(current === "The Database" && <ModalProject lang={lang} />) ||
            (current === "How To Use" && <ModalHowTo lang={lang} />) ||
            (current === "Questionnaire" && (
              <ModalQuestionnaire lang={lang} />
            )) ||
            (current === "Metadata" && <ModalMetadata lang={lang} />) ||
            (current === "Research Team" && <ModalOurTeam lang={lang} />) ||
            (current === "Credits" && <ModalCredits lang={lang} />)}
        </div>
      </div>
    </CSSTransition>
  );
}

export default ModalMenu;
