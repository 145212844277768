import React, { useState, useEffect } from "react";
import iconMapMarker from "../svg/icon__dropdown.svg";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

function createRange(range) {
  let ids = [];
  let start = parseInt(range[0], 10);
  let end = parseInt(range[1], 10);
  if (!isNaN(start) && !isNaN(end)) {
    for (let i = start; i <= end; i++) {
      ids.push(i);
    }
    return ids;
  }
  return [];
}

function DropdownMap({
  label,
  firstLoad,
  isActive,
  onToggle,
  onValueChange,
  onLangChange,
  token
}) {
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [maps, setMaps] = useState([]);
  const [mapLanguage, setMapLanguage] = useState("name_it");
  const [mapPage, setMapPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(true); // true — show all, false — show selected

  async function mapSearch(value = "", page = 1, tempToken = token) {
    setSearchValue(value);
    setLoading(false);
    let ids = [];
    if (value.includes(";")) {
      value.split(";").forEach(el => {
        console.log(el);
        if (el.includes(":")) {
          let range = el.split(":");
          ids = [...ids, ...createRange(range)];
        } else {
          let parsed = parseInt(el, 10);
          if (!isNaN(parsed)) ids.push(parsed);
        }
      });
    } else if (value.includes(":")) {
      let range = value.split(":");
      ids = createRange(range);
    }

    if (ids.length) {
      await axios
        .get(
          `https://romais01.uzh.ch/service/api/v1/ais/maps/?page=${page}&map_id__in=${ids.join(
            ","
          )}`,
          {
            headers: { authorization: "Token " + tempToken }
          }
        )
        .then(function(response) {
          setMaps(response.data.results);
          setMapPage(1);
        })
        .catch(function(error) {
          console.log(error);
        });
      return;
    }

    await axios
      .get(
        `https://romais01.uzh.ch/service/api/v1/ais/maps/?page=${page}&query=${value}`,
        {
          headers: { authorization: "Token " + tempToken }
        }
      )
      .then(function(response) {
        setMaps(response.data.results);
        setMapPage(1);
        if (selected.length === 0 && !value.length) {
          setSelected([response.data.results[0].id]);
          handleChange(response.data.results[0]["id"]);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    mapSearch("", 1, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function clearSearch() {
    setSearchValue("");
    mapSearch();
  }

  async function loadUp(e) {
    if (
      (e.offsetHeight + e.scrollTop >= e.scrollHeight - 20 ||
        e.offsetHeight === e.scrollHeight) &&
      !loading &&
      showAll
    ) {
      if (searchValue.includes(":")) {
        let range = searchValue.split(":");
        if (range.length === 2) {
          let start = parseInt(range[0], 10);
          let end = parseInt(range[1], 10);
          if (!isNaN(start) && !isNaN(end)) {
            return;
          }
        }
      }

      setLoading(true);
      console.log(maps)
      await axios
        .get(
          `https://romais01.uzh.ch/service/api/v1/ais/maps/?page=${mapPage +
            1}&query=${searchValue ? searchValue : ""}`,
          {
            headers: { authorization: "Token " + token }
          }
        )
        .then(function(response) {
          setMaps([...maps, ...response.data.results]);
          console.log(":)", maps);
          setMapPage(mapPage + 1);
          setLoading(false);
        })
        .catch(function(error) {
          // setLoading(false);
          console.log(error);
        });
    }
  }

  async function handleChange(id) {
    if (firstLoad === 1) {
      const firstIndex = selected.indexOf(1);
      let tmp = [];
      tmp = selected;
      tmp.splice(firstIndex, 1);
      setSelected(tmp);
    }
    const index = selected.indexOf(id);
    let temp = [];
    if (index === -1) {
      temp = [id, ...selected];
      setSelected(temp);
    } else {
      temp = selected;
      temp.splice(index, 1);
      setSelected(temp);
    }
    onValueChange(temp, token);
  }

  async function handleClick(e, index) {
    let isChecked = selected.includes(maps[index].id);
    if (lastSelected && e.shiftKey && lastSelected[1] === isChecked) {
      let temp = selected;

      let start = index > lastSelected[0] ? lastSelected[0] + 1 : index + 1;
      let end = index < lastSelected[0] ? lastSelected[0] : index;
      for (let i = start; i < end; ++i) {
        let k = selected.indexOf(maps[i].id);
        if (!isChecked && k === -1) {
          temp.push(maps[i].id);
        } else if (isChecked && k >= 0) {
          temp.splice(k, 1);
        }
      }

      setSelected(temp);
    } else {
      let k = selected.includes(maps[index].id);
      setLastSelected([index, k]);
    }
  }

  return (
    <div className="dropdown-wrapper">
      <button
        className="btn-dropdown"
        onClick={() => onToggle()}
        onMouseUp={() => (isActive ? document.activeElement.blur() : null)}
      >
        <div className="label">{label || "Maps"}</div>
        <img src={iconMapMarker} alt="Switch to map view" />
      </button>
      <CSSTransition
        in={isActive}
        timeout={120}
        classNames="dropdown"
        unmountOnExit
      >
        <div className="dropdown-drop search" style={{ minWidth: 310 }}>
          {searchValue && (
            <div className="clear" onClick={() => clearSearch()}></div>
          )}
          <div className="help-tip">
            ?
            <div className="content">
              You can search multiple id in ranges «5:20» or individual «2;6;14»
            </div>
          </div>
          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={e => mapSearch(e.target.value)}
          />
          <div
            id="map-list"
            className="dropdown-list"
            onScroll={e => loadUp(e.target)}
          >
            {maps &&
              maps.map((el, index) => {
                if (showAll || selected.includes(el.id)) {
                  return (
                    <label
                      className="item"
                      key={index + 1}
                      onClick={e => handleClick(e, index)}
                    >
                      <input
                        onChange={() => {
                          handleChange(el["id"]);
                        }}
                        type="checkbox"
                        checked={selected.includes(el["id"])}
                      />
                      {el["map_id"] + " – " + el[mapLanguage]}
                    </label>
                  );
                } else return null;
              })}
          </div>
          <div className="dropdown-panel">
            <div className="languages">
              <div
                className={
                  "lang " + (mapLanguage === "name_it" ? "active" : "alt")
                }
                onClick={e => {
                  e.stopPropagation();
                  setMapLanguage("name_it");
                  onLangChange("name_it");
                }}
              >
                IT
              </div>
              <div
                className={
                  "lang " + (mapLanguage === "name_fr" ? "active" : "alt")
                }
                onClick={e => {
                  e.stopPropagation();
                  setMapLanguage("name_fr");
                  onLangChange("name_fr");
                }}
              >
                FR
              </div>
              <div
                className={
                  "lang " + (mapLanguage === "name_de" ? "active" : "alt")
                }
                onClick={e => {
                  e.stopPropagation();
                  setMapLanguage("name_de");
                  onLangChange("name_de");
                }}
              >
                DE
              </div>
            </div>
            <div>
              <button
                className="switch"
                onClick={() => {
                  setShowAll(!showAll);
                  setLastSelected(null);
                }}
                disabled={!selected.length}
              >
                {showAll ? "SHOW SELECTED" : "SHOW ALL"}
              </button>
              <button
                className="switch"
                onClick={() => {
                  setSelected([]);
                  setShowAll(true);
                  onValueChange([]);
                }}
                disabled={!selected.length}
              >
                CLEAR
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default DropdownMap;
