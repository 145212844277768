import React from "react";

function ModalProject({ lang }) {
  if (lang === "en") {
    return (
      <div className="window-content en">
        <h2>THE DATABASE</h2>
        <p><em>AIS, reloaded</em> is a freely searchable and downloadable web-database which presents</p>
<p>1) a searchable digital version of all the linguistic materials of the Linguistic and Ethnographic Atlas of Italy and Southern Switzerland / <em>Sprach- und Sachatlas Italiens und der S&uuml;dschweiz</em>&nbsp;/ <em>Atlante italo-svizzero</em>, (AIS, 1928-1940);</p>
<p>2) a comparable corpus of new data for the dialects of Lombardy and Southern Switzerland corresponding to the original AIS datapoints.</p>
<p>The digitalised content of the 1,705 AIS maps provides a detailed picture of 407 dialects as they were spoken between 1919 and 1928, when the fieldwork data were collected in Italy and Southern Switzerland. The new corpus of Lombard and Romansh data has been collected using the same questionnaire in the same localities (86 datapoints in total) almost one hundred years later and is therefore suitable to document diachronic change in the corresponding dialects in the elapsed century. For each datapoint, an informant has been selected and interviewed using the AIS intermediate questionnaire.</p>
<p>The digitized maps from the 1920&rsquo;s AIS and the data (transcriptions and related audio files) from the new surveys included in the <em>AIS, reloaded</em> database are the results of the joint contribution of the research projects <em>AIS, reloaded</em>and <em>AIS, the digital turn</em>, both funded by the Swiss National Science Foundation and hosted by the Romanisches Seminar (RoSe) of the University of Zurich.</p>
<p><strong><em>AIS</em></strong><strong>, <em>reloaded</em> (AISr)</strong> [SNF 100012-162482, 2016-2019]</p>
<p>In the framework of AISr, from 2016 to 2019, 50% of the transcriptions contained in the 1920&rsquo;s AIS were digitized (maps 1-880; about 700,000 entries in all). In addition, new data from 36 AIS datapoints in Southern Switzerland (18 for the Lombard varieties in Canton Tessin and in the Italian part of the Canton of Grisons and 18 for Romance varieties in the Canton of Grisons) were collected, recorded and transcribed (about 61,000 entries). <a href="https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS-reloaded.html">https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS-reloaded.html</a></p>
<p><strong><em>AIS, the digital turn</em></strong><strong> (AISdt)</strong> [SNF 100012_192259, 2021-2025]</p>
<p>The ongoing project AISdt will, within 2025, complete the process of digitalization of the 1920&rsquo;s AIS linguistic content (maps 881-1705, and supplementary material, i.e. <em>Legende</em>). Moreover, it will progressively upload new recordings and transcriptions from the 50 Lombard AIS datapoints in Lombardy and Piedmont.<a href="https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS,-the-digital-turn.html">https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS,-the-digital-turn.html</a> </p>
<p>References</p>
<p>Jaberg, K., Jud, J. (1928-1940). <em>Sprach- und Sachatlas Italiens und der S&uuml;dschweiz. </em>Vol. 1-8. Zofingen: Ringier.<br />Jaberg, K., Jud, J. (1928). <em>Der Sprachatlas als Forschungsinstrument</em>. Halle: Niemeyer. Rist. Nendeln: Kraus, 1973.<br />Jaberg, K., Jud, J. (1960). <em>Index zum Sprach- und Sachatlas Italiens und der S&uuml;dschweiz</em><em>. Ein prop&auml;deutisches etymologisches W&ouml;rterbuch <u>der</u> italienischen Mundarten</em>. Bern:&nbsp;St&auml;mpfli.</p>
<p>Loporcaro, Michele, Stephan Schmid, Chiara Zanini, Diego Pescarini, Giulia Donzelli, Stefano Negrinelli and Graziano Tisato. 2021. AIS, reloaded: A digital dialect atlas of Italy and southern Switzerland. In: Thibaut, Andr&eacute;, Matthieu Avanzi, Nicolas Lo Vecchio and Alice Millour (eds). <em>Nouveaux regards sur la variation dialectale. Strasbourg: Editions de Linguistique et de Philologie</em>, 111&ndash;136.</p>
      </div>
    );
  } else if (lang === "it") {
    return (
      <div className="window-content it">
        <h2>LA BANCA DATI</h2>
        <p>La banca dati liberamente interrogabile e scaricabile attraverso il presente sito fornisce</p>
<p>&nbsp;1) una versione digitale interrogabile di tutti materiali linguistici dello&nbsp;<em>Sprach- und Sachatlas Italiens und der S&uuml;dschweiz</em>&nbsp;/ <em>Atlante italo-svizzero</em> (AIS, 1928-1940);</p>
<p>2) nuovi dati per i punti di inchiesta AIS della Svizzera meridionale e delle variet&agrave; lombarde di Lombardia e Piemonte direttamente comparabili al corpus AIS originario.</p>
<p>Le 1.705 mappe dell&rsquo;AIS forniscono un quadro dettagliato di 407 variet&agrave; linguistiche cos&igrave; com&rsquo;erano parlate fra il 1919 e il 1928, periodo in cui vennero svolte le inchieste in Italia e Svizzera meridionale. Il nuovo corpus di dati relativo alle variet&agrave; lombarde e romance corrispondenti ai punti d&rsquo;inchiesta AIS (86 variet&agrave; in totale), &egrave; stato raccolto col medesimo questionario, nelle medesime localit&agrave;, a quasi cento anni di distanza ed &egrave; atto, dunque, a documentare l&rsquo;evoluzione diacronica di tali dialetti nel secolo intercorso. Per ciascuna localit&agrave; &egrave; stato selezionato e intervistato un informatore usando il questionario intermedio dell&rsquo;AIS.</p>
<p>La digitalizzazione delle mappe dell&rsquo;AIS e l&rsquo;elaborazione dei dati delle nuove inchieste (trascrizioni fonetiche corredate dai rispettivi file audio) sono il risultato del lavoro di ricerca svolto nell&rsquo;ambito dei progetti <em>AIS, reloaded</em> e <em>AIS, the digital turn</em>, condotti presso il Romanisches Seminar (RoSe) dell&rsquo;Universit&agrave; di Zurigo efinanziati dal&nbsp;<a href="http://www.snf.ch/">Fondo Nazionale Svizzero</a>.</p>
<p><em>AIS, reloaded</em> (AISr) [SNF 100012-162482, 2016-2019]</p>
<p>Sotto l&rsquo;egida del progetto AISr, dal 2016 al 2019 &egrave; stato digitalizzato il 50% del corpus delle trascrizioni contenute nelle mappe principali dell&rsquo;AIS originario (mappe 1-880, per un totale di circa 700.000 entrate) e sono stati raccolti, registrati e trascritti i nuovi dati (circa 61.000 entrate) provenienti dalle 36 localit&agrave; della Svizzera meridionale (18 per le variet&agrave; lombarde nel Canton Ticino e del Grigioni italiano e 18 per le variet&agrave; romance del Canton Grigioni). <a href="https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS-reloaded.html">https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS-reloaded.html</a></p>
<p><em>AIS, the digital turn</em> (AISdt) [SNF 100012_192259, 2021-2025]</p>
<p>A questi dati, durante il quadriennio 2021-2025, si aggiungeranno man mano quelli digitalizzati e raccolti in seno al progetto AISdt, e dunque riguardanti la digitalizzazione del restante 50% delle mappe AIS (mappe 881-1.705) e di tutto il materiale linguistico aggiuntivo (legende, paradigmi, carte illustrate). A ci&ograve; si aggiungeranno i nuovi dati provenienti dalle 50 variet&agrave; lombarde indagate dall&rsquo;AIS in Lombardia e Piemonte. <a href="https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS,-the-digital-turn.html">https://www.rose.uzh.ch/de/forschung/forschungamrose/projekte/AIS,-the-digital-turn.html</a> </p>
<p>Riferimenti</p>
<p>Jaberg, K., Jud, J. (1928-1940). <em>Sprach- und Sachatlas Italiens und der S&uuml;dschweiz. </em>Voll. 1-8. Zofingen: Ringier.<br />Jaberg, K., Jud, J. (1928). <em>Der </em><em>Sprachatlas als Forschungsinstrument</em>. Halle: Niemeyer. Rist. Nendeln: Kraus, 1973.<br />Jaberg, K., Jud, J. (1960). <em>Index zum Sprach- und Sachatlas Italiens und der S&uuml;dschweiz</em><em>. Ein prop&auml;deutisches etymologisches W&ouml;rterbuch <u>der</u> italienischen Mundarten</em>. Bern:&nbsp;St&auml;mpfli.</p>
<p>Loporcaro, Michele, Stephan Schmid, Chiara Zanini, Diego Pescarini, Giulia Donzelli, Stefano Negrinelli and Graziano Tisato. 2021. AIS, reloaded: A digital dialect atlas of Italy and southern Switzerland. In: Thibaut, Andr&eacute;, Matthieu Avanzi, Nicolas Lo Vecchio and Alice Millour (eds). <em>Nouveaux regards sur la variation dialectale. Strasbourg: Editions de Linguistique et de Philologie</em>, 111&ndash;136.</p>
      </div>
    );
  }
}

export default ModalProject;
