import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Fuse from "fuse.js";

import iconMapMarker from "../svg/icon__dropdown.svg";

function createRange(range) {
  let ids = [];
  let start = parseInt(range[0], 10);
  let end = parseInt(range[1], 10);
  if (!isNaN(start) && !isNaN(end)) {
    for (let i = start; i <= end; i++) {
      ids.push(i);
    }
    return ids;
  }
  return [];
}

function DropdownLocation({ label, isActive, list, onToggle, onValueChange }) {
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [filteredList, setFilteredList] = useState(list);
  const [searchValue, setSearchValue] = useState("");
  const [showAll, setShowAll] = useState(true); // true — show all, false — show selected

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  function handleSearchChange(e) {
    setSearchValue(e.target.value);

    if (e.target.value === "") {
      setFilteredList(list);
      return;
    } else if (e.target.value.includes(";")) {
      let ids = [];

      e.target.value.split(";").forEach(el => {
        if (el.includes(":")) {
          let range = el.split(":");
          ids = [...ids, ...createRange(range)];
        } else {
          ids.push(parseInt(el, 10));
        }
      });

      let filtered = list.filter(el => {
        if (ids.includes(el.ais_id)) return true;
        else return false;
      });

      setFilteredList(filtered);
      return;
    } else if (e.target.value.includes(":")) {
      let range = e.target.value.split(":");
      let ids = createRange(range);

      let filtered = list.filter(el => {
        if (ids.includes(el.ais_id)) return true;
        else return false;
      });

      setFilteredList(filtered);
      return;
    }

    const options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: ["name", "ais_id"]
    };
    const fuse = new Fuse(list, options);
    const result = fuse.search(e.target.value);
    if (result.length === 0) {
      setFilteredList(list);
    }

    setFilteredList(result);
  }

  function clearSearch() {
    setSearchValue("");
    setFilteredList(list);
  }

  async function handleChange(id) {
    const index = selected.indexOf(id);
    let temp = [];
    if (index === -1) {
      temp = [id, ...selected];
      setSelected(temp);
    } else {
      temp = selected;
      temp.splice(index, 1);
      setSelected(temp);
    }
    onValueChange(temp);
  }

  async function handleClick(e, index) {
    let isChecked = selected.includes(filteredList[index].id);
    if (lastSelected && e.shiftKey && lastSelected[1] === isChecked) {
      let temp = selected;

      let start = index > lastSelected[0] ? lastSelected[0] + 1 : index + 1;
      let end = index < lastSelected[0] ? lastSelected[0] : index;
      for (let i = start; i < end; ++i) {
        let k = selected.indexOf(filteredList[i].id);
        if (!isChecked && k === -1) {
          temp.push(filteredList[i].id);
        } else if (isChecked && k >= 0) {
          temp.splice(k, 1);
        }
      }

      setSelected(temp);
    } else {
      let k = selected.includes(filteredList[index].id);
      setLastSelected([index, k]);
    }
  }

  return (
    <div className="dropdown-wrapper">
      <button
        className="btn-dropdown"
        onClick={() => onToggle()}
        onMouseUp={() => (isActive ? document.activeElement.blur() : null)}
      >
        <div className="label">{label || "Locations"}</div>
        <img src={iconMapMarker} alt="Switch to map view" />
      </button>
      <CSSTransition
        in={isActive}
        timeout={120}
        classNames="dropdown"
        unmountOnExit
      >
        <div className="dropdown-drop search">
          {searchValue && (
            <div className="clear" onClick={() => clearSearch()}></div>
          )}
          <div className="help-tip">
            ?
            <div className="content">
              You can search multiple id in ranges «5:20» or individual «2;6;14»
            </div>
          </div>
          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={e => {
              handleSearchChange(e);
            }}
          />
          <div className="dropdown-list">
            <label
              className="item"
              key={0}
              onClick={() => {
                setSelected([]);
                onValueChange([]);
                setShowAll(true);
              }}
            >
              All locations
            </label>
            {filteredList.map((el, index) => {
              if (showAll || selected.includes(el.id)) {
                return (
                  <label
                    className="item"
                    key={index + 1}
                    onClick={e => {
                      handleClick(e, index);
                    }}
                  >
                    <input
                      onChange={() => {
                        handleChange(el["id"]);
                      }}
                      type="checkbox"
                      checked={selected.includes(el["id"])}
                    />
                    {el["ais_id"] + " – " + el["name"]}
                  </label>
                );
              } else return null;
            })}
          </div>
          <div className="dropdown-panel">
            <div />
            <div>
              <button
                className="switch"
                onClick={() => {
                  setShowAll(!showAll);
                  setLastSelected(null);
                }}
                disabled={!selected.length}
              >
                {showAll ? "SHOW SELECTED" : "SHOW ALL"}
              </button>
              <button
                className="switch"
                onClick={() => {
                  setShowAll(true);
                  setSelected([]);
                  onValueChange([]);
                }}
                disabled={!selected.length}
              >
                CLEAR
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default DropdownLocation;
