import React from "react";
import QuestionnaireTicino from "../files/ticino_grisons.xlsx";
import QuestionnaireRomansch from "../files/romansch_grisons.xlsx";

function ModalQuestionnaire({ lang }) {
  if (lang === "en") {
    return (
      <div className="window-content en">
        <h2>QUESTIONNAIRE</h2>
<p>Data collection was carried out through interviews involving one single informant per datapoint. The survey lasted 12 hours on average (split into four sessions of three hours each) for each informant and was based on the AIS intermediate questionnaire covering all the semantic fields investigated in the 1920s AIS. These latter are reported in what follows as in the original partition (each volume corresponds to approximately 200 maps):</p>
<ul>
<li>Volume I: Relationship. Age. Love, birth, marriage and death. Given names. Body parts. Body functions. Physical qualities and defects.</li>
<li>Volume II: Crafts and tools. Business. Numbers. Time and space. Celestial bodies. Atmospheric phenomena. Metals.</li>
<li>Volume III: Minerals. Soil and water configuration. Animals. Hunting and fishing. Forestry and woodcutter&rsquo;s tools. Plants.</li>
<li>Volume IV: Rest and toiletries. Diseases and healing. Moral qualities and defects, feelings. Religious and social life.</li>
<li>Volume V: Home and household goods. Food. Eating and drinking.</li>
<li>Volume VI: Large and small cattle breeding. Beekeeping. Sericulture. Pasture and mountain pasture. Wagon, yoke and harness.</li>
<li>Volume VII: Fruit trees and fruits. Viticulture and winemaking. Oil production. Vegetable garden and garden, herbs and vegetables. Potato cultivation. Mower tools. Lawn and field. Irrigation and field working. Cereals and their cultivation. Threshing. Wheat trim and preservation.</li>
<li>Volume VIII: Baskets. Hemp and linen processing. Spinning and weaving. Laundry. Sewing. Dressing and footwear. Adjectives. Phrases: conversation pieces. Conjugation tables.</li>
</ul>
<p>The questionnaire was presented and informants&rsquo; answers were recorded by means of the <a href="https://www.bas.uni-muenchen.de/forschung/Bas/software/speechrecorder/">SpeechRecorder</a> software. Each interview session was video-recorded as well. The integral video recordings are stored at the <em>Phonogrammarchiv</em> of the University of Zurich: <a href="mailto:phonogrammarchiv@ivs.uzh.ch">phonogrammarchiv@ivs.uzh.ch</a>.</p>
<p>The questionnaire was administered following the entries' arrangement in the original one. The progressive numbering of the questions does not reflect that of the maps in the AIS atlas; rather, each interview begins by asking for the translation of simple words, such as numbers, days of the week and kinship terms, to then continue with words and phrases of increasing difficulty. As for surveys in the Canton of Ticino, in the Italian part of Canton of Grisons and in Italy, the interviews were conducted in Italian; on the other hand, German and Romansh were used during interviews in the Romansh-speaking part of Grisons. The Italian version of the questionnaire, which is 1,736 entries long, as well as the German one, which is 1,812 entries long, are reported here below. The entries that correspond to a regular map (type: M) are labelled differently from the entries that correspond instead to a legend at the edges of the map (type: L) or to a verb conjugation (type: C). Each entry of the questionnaire is identified with a number (ID) consisting of the page number (ID_page) and the line number (ID_position) that the entry occupies in the original AIS questionnaire, plus the map number which this entry is associated to (ID_map).</p>
        <p>
          <a href={QuestionnaireTicino} download>
            Ticino and Italian Grisons questionnaire
          </a>
          <br />
          <a href={QuestionnaireRomansch} download>
            Romansch Grisons questionnaire
          </a>
        </p>
      </div>
    );
  } else if (lang === "it") {
    return (
      <div className="window-content it">
        <h2>QUESTIONARIO </h2>
        <p>La raccolta dei dati &egrave; avvenuta mediante interviste a questionario con un singolo informatore per punto. L&rsquo;inchiesta &egrave; durata in media 12 ore (suddivise in quattro sessioni da tre ore ciascuna) per ogni informatore e si &egrave; basata sul questionario intermedio dell&rsquo;AIS ricoprendo tutti i campi semantici presenti nell&rsquo;AIS originario che si riportano qui di seguito seguendo la partizione dell&rsquo;opera (ogni volume corrisponde a circa 200 mappe):</p>
<ul>
<li>I Volume: Parentela. Et&agrave;. Amore, nascita, matrimonio e morte. Nomi di battesimo. Parti del corpo. Funzioni del corpo. Qualit&agrave; e difetti fisici.</li>
<li>II Volume: Mestieri e arnesi. Commercio. Numeri. Tempo e spazio. Corpi celesti. Fenomeni atmosferici. Metalli.</li>
<li>III Volume: Minerali. Configurazione del suolo e acque. Animali. Caccia e pesca. Silvicoltura e arnesi dello spaccalegna. Piante.</li>
<li>IV Volume: Riposo e toeletta. Malattie e guarigione. Difetti, qualit&agrave; morali e sentimenti. Vita religiosa e sociale.</li>
<li>V Volume: Casa e masserizie. Cibi. Mangiare e bere.</li>
<li>VI Volume: Allevamento del bestiame grosso e minuto. Apicoltura. Bachicoltura. Pascolo e alpeggio. Carro, giogo e finimenti.</li>
<li>VII Volume: Alberi fruttiferi e frutti. Viticoltura e vinificazione. Fabbricazione dell'olio. Orto e giardino, erbaggi e legumi. Coltivazione delle patate. Arnesi del falciatore. Prato e campo. Irrigazione e lavorazione del campo. Cereali e loro coltivazione. Trebbiatura. Mondatura e conservazione del grano.</li>
<li>VIII Volume: Panieri. Lavorazione della canapa e del lino. Filatura e tessitura. Bucato. Cucitura. Vestimento e calzatura. Aggettivi. Frasi: brani di conversazione. Tavole della coniugazione.&nbsp;</li>
</ul>
<p>La presentazione del questionario, che include anche materiale visivo, e la registrazione audio sono state effettuate mediante il software&nbsp;<a href="https://www.bas.uni-muenchen.de/forschung/Bas/software/speechrecorder/"><em>SpeechRecorder</em></a>. Ogni intervista &egrave; stata anche registrata con una videocamera. Le videoregistrazioni integrali sono depositate presso il <em>Phonogrammarchiv</em> dell&rsquo;Universit&agrave; di Zurigo (<a href="mailto:phonogrammarchiv@ivs.uzh.ch">phonogrammarchiv@ivs.uzh.ch</a>).</p>
<p>L&rsquo;ordine di presentazione del questionario &egrave; coerente con quello degli anni &rsquo;20 del secolo scorso: la progressione numerica delle domande non rispecchia quella delle mappe nell&rsquo;atlante; al contrario, l&rsquo;intervista inizia chiedendo la traduzione di parole semplici, come i numeri, i giorni della settimana e i nomi di parentela, per poi proseguire con parole e frasi di crescente complessit&agrave;. Qui di seguito si riporta la versione effettivamente usata per le inchieste nel Canton Ticino, nel Grigioni italiano, e in Italia (1.736 entrate), condotte in italiano, e quella poco pi&ugrave; lunga per le inchieste sulle variet&agrave; romance nel Canton Grigioni (1.812 entrate), condotte in tedesco e in romancio. Si sono distinte le entrate che corrispondono a una mappa vera e propria (type: M), dalle entrate che invece corrispondono alle legende a bordo mappa (type: L) o alle coniugazioni verbali (type: C). Ogni entrata del questionario &egrave; identificata con un numero (ID) composto dal numero di pagina (ID_page) e di riga (ID_position) occupate dall&rsquo;entrata nel questionario originario degli anni &rsquo;20 del secolo scorso e dal numero di mappa cui tale entrata &egrave; associata (ID_map).</p>
        <p>
          <a href={QuestionnaireTicino} download>
            Questionario per il Ticino e il Grigioni italiano
          </a>
          <br />
          <a href={QuestionnaireRomansch} download>
            Questionario per il Grigioni romancio
          </a>
        </p>
      </div>
    );
  }
}

export default ModalQuestionnaire;
